import { Atom, Cmp, state } from ":mods";
import { For, Match, Show, Switch, batch, createEffect, splitProps } from "solid-js";
import { EditableViewProps } from "../model";
import "../style/list-editable.css";
const NEW_TEM_KEY = "$$$NEW_TEM_KEY$$$";
export function Editable<T extends Record<string | number, any>>(props: EditableViewProps<T>) {
  const [local, others] = splitProps(props, [
    "each",
    "options",
    "kebabToCamel",
    "instantEdit",
    "events",
    "processValue",
  ]);
  // TODO: allow realtime edit with instantEdit
  // const $instant_edit = state.create(local.instantEdit ?? false);
  const $items = state.create(undefined as any[]);
  const $add_group = state.create(false);
  const items_is_array = Array.isArray(local.each);
  const $options = $items.derive((v) => {
    if (items_is_array) {
      return undefined;
    }
    if (!local.options || local.options.length <= 0) {
      return undefined;
    }
    const latest_items = v;
    if (!latest_items) {
      return undefined;
    }
    const keys = [];

    local.options.forEach((k) => {
      if (!latest_items[k]) {
        keys.push(k);
      }
    });
    return keys;
  });
  let new_text_json: string = undefined;

  const actions = [
    {
      title: "edit" as const,
      icon: "icon-basil:edit-outline cursor-pointer",
    },
    {
      title: "delete" as const,
      icon: "icon-mdi:delete cursor-pointer",
      onClick: (index: number) => {
        const kvp = $items.value[index];
        $items.set((s) => {
          s.splice(index, 1);
          return [...s];
        });
        const processed_items = processItemsChange($items.value, [index, kvp[1]]);
        local.events.delete?.(processed_items.items, processed_items.kvp, index);
      },
    },
    {
      title: "move" as const,
      icon: "icon-iconamoon:move-bold cursor-cell",
    },
  ];

  const edit_actions = [
    {
      title: "save" as const,
      icon: "icon-mdi:check-circle cursor-pointer",
    },
    {
      title: "cancel" as const,
      icon: "icon-mdi:close-circle cursor-pointer",
    },
  ];

  createEffect(() => {
    $items.set(Object.entries(local.each || {}));
  });

  function processItemsChange(items: [string | number, any][], kvp: [string | number, any]) {
    let s = undefined;
    if (items_is_array) {
      s = items.map(([, v]) => v);
    } else {
      s = Object.fromEntries(items);
    }
    return {
      items: s as T,
      kvp,
    };
  }

  function onOrderChange(items: [string, any][]) {
    $items.set(items);
    let s = undefined;
    if (items_is_array) {
      s = items.map(([, v]) => v);
    } else {
      s = Object.fromEntries(items);
    }
    local.events?.reorder?.(s as T, undefined);
  }

  function onItemUpdate(
    index: string | number,
    is_new_item: boolean,
    old_kvp: [string | number, any],
    new_kvp: [string | number, any],
    action: "save" | "cancel"
  ) {
    const old_k = old_kvp[0];
    const new_k = new_kvp[0],
      new_v = new_kvp[1];
    console.log("new k vs old k :: ", new_k === old_k, " :: ", new_k, " :: ", old_k);
    if (is_new_item && new_k === old_k) {
      return false;
    }
    batch(() => {
      if (action === "save") {
        $items.set((s) => {
          s[index] = new_kvp;
          console.log("new kvp ", s[index]);
          return [...s];
        });
        const processed_items = processItemsChange($items.value, [items_is_array ? index : (new_k as any), new_v]);
        local.events?.update?.(processed_items.items, processed_items.kvp);
      } else {
        $items.set((s) => {
          s[index] = old_kvp;
          return [...s];
        });
      }
    });
    return true;
  }

  function onAddClicked() {
    if ($add_group.value) {
      try {
        function kebabToCamel(str: string) {
          return str.replace(/-(\w)/g, (_, c) => c.toUpperCase());
        }
        const ignored_keys: string[] = [];
        function stringToObject(str: string) {
          return str.split(";").reduce((acc, curr) => {
            let [key, value] = curr.split(":");
            if (key && key !== "" && value) {
              key = !key.startsWith("{") ? key.trim() : key.replaceAll("{", "").trim();
              value = !value.endsWith("}") ? value.trim() : value.replaceAll("}", "").trim();
              if (local.kebabToCamel) {
                key = kebabToCamel(key);
              }
              if ($options() && $options().indexOf(key) < 0) {
                ignored_keys.push(key);
              } else {
                acc.push([key, value]);
              }
            }
            return acc;
          }, []);
        }
        const kvps = stringToObject(new_text_json);

        console.log("kvps :: ");

        if (ignored_keys.length > 0) {
          local.events?.error?.("adding_group_key", ignored_keys.join(","));
        } else if (!kvps || kvps.length <= 0) {
          local.events?.error?.("adding_group", "wrong json syntax or no keys to add");
        }
        if (kvps && kvps.length > 0) {
          $items.set((s) => {
            return [...s, ...kvps];
          });
        }

        // new_text_json = undefined;
      } catch (e: any) {
        if (local.events?.error) {
          local.events?.error?.("processing_group", "cannot parse json keys", e);
        } else {
          console.log("cannot parse json,", e);
        }
      }
    } else {
      $items.set((s) => {
        const index = s.length;
        const value =
          local.processValue?.set?.("New Item Value", undefined, { current: index, constant: index }, true) ??
          "New Item Value";
        let key = String(index);
        if (!items_is_array) {
          key = NEW_TEM_KEY;
        }
        s.push([key, value]);
        return [...s];
      });
    }
    const processed_items = processItemsChange($items.value, $items.value[$items.value.length]);
    local.events?.add?.(processed_items.items, processed_items.kvp);
  }

  function getItemValue(v: any, index: { current: number; constant: number }, newElement: boolean) {
    return local.processValue?.get?.(v, index, newElement) ?? v;
  }
  return (
    <section {...others}>
      <Cmp.List.DragNDrop each={$items.value} onSwap={(items) => onOrderChange(items)}>
        {([k, v]: [keyof T, any], index, dragEvent, dragStart, dragEnd) => {
          const is_new_item = typeof k === "string" && k.startsWith(NEW_TEM_KEY);
          const $edit = state.create(is_new_item ? true : false);
          const $update_error_occured = state.create(false);
          const $mouse_entered = state.create(false);
          const const_index = index();
          const old_k = k;
          const old_v = v;
          const get_item_v = () => getItemValue(v, { current: index(), constant: const_index }, is_new_item);

          // createEffect(() => {
          //   console.log("item ", k, " :: ", v, " :: ", const_index, " :: ", index(), " :: ", is_new_item);
          // });
          return (
            <div
              {...dragEnd}
              onMouseEnter={() => $mouse_entered.set(true)}
              onMouseLeave={() => $mouse_entered.set(false)}
              class="relative flex flex-row w-full h-50px row"
            >
              {/* info */}
              <div
                class=" flex flex-row w-full row-kvp-group"
                onClick={() => !$edit.value && local.events?.clicked(items_is_array ? get_item_v() : k, v, index)}
                classList={{ hovered: $mouse_entered.value && !$edit.value }}
              >
                <Show when={!items_is_array}>
                  <Show
                    when={$edit.value && $options()}
                    fallback={
                      <span
                        contenteditable={$edit.value}
                        oninput={(e) => (k = e.currentTarget.textContent)}
                        class={`
                        ${$edit.value ? "outline-2 outline-dashed outline#p" : " "}
                        row-kvp-key
                        `}
                      >
                        {is_new_item ? "New Item Key" : (k as string)}
                      </span>
                    }
                  >
                    <Atom.Form.Select
                      class={`flex flex-col ${
                        $update_error_occured.value
                          ? " animate animate-bounce outline-red outline-dashed outline-2 "
                          : "  "
                      } row-kvp-select`}
                      control={[is_new_item ? "Select..." : String(k)]}
                      onSelected={(value) => (k = value)}
                      options={$options()}
                      value={String(k)}
                    />
                  </Show>
                  <span class="row-kvp-separator">:</span>
                </Show>
                <span
                  contenteditable={$edit.value}
                  oninput={(e) =>
                    (v =
                      local.processValue?.set?.(
                        e.currentTarget.textContent,
                        v,
                        {
                          current: index(),
                          constant: const_index,
                        },
                        is_new_item
                      ) ?? e.currentTarget.textContent)
                  }
                  class={`
                  ${$edit.value ? "border-2 border-dashed border#p" : " "}
                  row-kvp-value
                  `}
                >
                  {getItemValue(v, { current: index(), constant: const_index }, is_new_item)}
                </span>
              </div>
              {/* actions */}
              <div class="flex flex-row justify-end items-center w-fit row-action-group">
                <For each={actions}>
                  {(action, actionIndex) => {
                    return (
                      <Show
                        when={action.title === "edit" && $edit.value && !local.instantEdit}
                        fallback={
                          <i
                            {...(action.title === "move" ? dragStart : {})}
                            class={`${action.icon} w-24px h-24px row-action`}
                            onClick={(e) => (action.title === "edit" ? $edit.set(true) : action.onClick(index()))}
                          />
                        }
                      >
                        <div class="flex flex-row space-x-2">
                          <For each={edit_actions}>
                            {(edit_action, editIndex) => {
                              if (is_new_item && edit_action.title === "cancel") return null;
                              return (
                                <i
                                  class={`${edit_action.icon} w-24px h-24px row-action`}
                                  onClick={(e) => {
                                    const action = edit_action.title === "save" ? "save" : "cancel";
                                    const successfuly_update = onItemUpdate(
                                      index(),
                                      is_new_item,
                                      [old_k as any, old_v],
                                      [k as any, v],
                                      action
                                    );
                                    if (!successfuly_update) {
                                      $update_error_occured.set(true);
                                      setTimeout(() => {
                                        if ($update_error_occured.value) {
                                          $update_error_occured.set(false);
                                        }
                                      }, 2000);
                                    } else {
                                      $edit.set(false);
                                    }
                                  }}
                                />
                              );
                            }}
                          </For>
                        </div>
                      </Show>
                    );
                  }}
                </For>
              </div>
            </div>
          );
        }}
      </Cmp.List.DragNDrop>
      <div class="flex flex-row w-full h-50px px-2 items-center self-end justify-end ">
        <Show when={$add_group.value}>
          <Atom.Form.Input control={[new_text_json]} onChange={(e) => (new_text_json = e)} class="new-kvp-input" />
        </Show>
        <Show when={!items_is_array}>
          <i
            class="icon-system-uicons:box-add w-35px h-35px cursor-pointer"
            onClick={() => $add_group.set((s) => !s)}
          />
        </Show>
        <i
          class={`${
            !$add_group.value ? " icon-mdi:plus-circle " : " icon-mdi:format-list-group-add "
          } w-35px h-35px cursor-pointer`}
          onClick={onAddClicked}
        />
      </div>
    </section>
  );
}
